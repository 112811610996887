import { StaticImage } from "gatsby-plugin-image";
import React from "react"
import Layout from "../../components/Layout"

const SingleProject = () => {
  return (
    <div>
      <Layout>
        <div className="flex flex-col items-center justify-center py-24 px-6 lg:px-16 case_study_bg text-white lg:h-96">
          <h1 className="text-3xl lg:text-6xl font-bold text-center">
            THE MOST SPECTACULAR MALTINA DANCE ALL (MDA) SEASON VI
          </h1>
          <p className="lg:w-100 text-xl font-light pt-8">
            We unlock growth opportunities for our clients by developing
            innovative media solutions that connect brands to target audience.
          </p>
        </div>
        <div className="flex items-center justify-center py-8 px-6 lg:px-32">
          <article className=" font-thin py-16 w-full ">
            <div className="flex lg:flex-row flex-col">
              <div className="flex flex-col w-full lg:w-1/2  pr-0 lg:pr-12">
                <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/Maltblog.jpg"
                  placeholder="blurred"
                  className="my-4 h-72"
                  layout="fullWidth"
                />
                <h1 className="text-xl font-bold items-center ">CURRENCY</h1>

                <div className="">
                  <p className="font-light">
                    {" "}
                    The MDA VI show succeeded to be one of the best family dance
                    reality show in Nigeria with a huge family and viewer
                    engagement. There was an estimated 22 million viewers and
                    4,000+ participants. The platform provided an opportunity to
                    share Happy Moments with loved ones.
                  </p>
                </div>
              </div>

              <div className="flex flex-col w-full lg:w-1/2  pt-8 lg:pt-0">
                <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/BOYLE FAMILY.jpg"
                  placeholder="blurred"
                  className="my-4 h-72"
                  layout="fullWidth"
                />
                <h1 className="text-xl font-bold items-center ">
                  CONVERSATION
                </h1>

                <div className="">
                  <p className="font-light">
                    {" "}
                    The campaign was communicated via TV, Radio, Print, Digital
                    (Computer &amp; Mobile), Static &amp; Mobile Transit OOH to
                    engaged the TA.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex lg:flex-row flex-col pt-12">
              <div className="flex flex-col w-full lg:w-1/2 pr-0 lg:pr-12">
                <h1 className="text-xl font-bold items-center ">CONTENT</h1>
                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/Maltina-Dance-All-1280x720.jpg"
                  placeholder="blurred"
                  className="my-4"
                /> */}
                <div className="">
                  <p className="font-light">
                    {" "}
                    Apart from creating awareness for the TA to register online
                    for the main event, people were asked to upload their best
                    dance steps and stand the chance of winning amazing prizes.
                  </p>
                </div>
              </div>

              <div className="flex flex-col w-full lg:w-1/2 pt-8 lg:pt-0">
                <h1 className="text-xl font-bold items-center ">CONVERSION</h1>
                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/Maltblog.jpg"
                  placeholder="blurred"
                  className="my-4"
                /> */}
                <div className="">
                  <p className="font-light">
                    Brand awareness increased significantly, with the Facebook
                    page growing from 40,000 to 100,000+ followers at the end of
                    the campaign.
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
      </Layout>
    </div>
  )
}

export default SingleProject
